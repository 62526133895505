// If you want to override variables do it here
@import "variables";

$enable-ltr: true; /* stylelint-disable-line scss/dollar-variable-default */
$enable-rtl: true; /* stylelint-disable-line scss/dollar-variable-default */

// Import styles
@import "@coreui/coreui/scss/coreui";

@import "layout";

// Bootstrap icons
@import '~bootstrap-icons/font/bootstrap-icons';

// Sweetalert2
@import '~sweetalert2/src/sweetalert2.scss';

// If you want to add custom CSS you can put it here.
@import "custom";