// Here you can add other styles

/* Si queremos ocultar el scrollbar https://www.w3schools.com/howto/howto_css_hide_scrollbars.asp */
/* Cambia el estilo del scrollbar del sidebar */
/* Works on Firefox */
.sidebar-nav {
  scrollbar-width: thin;
  scrollbar-color: #1E2532 #3C4B64;
}

/* Works on Chrome, Edge, and Safari */
.sidebar-nav::-webkit-scrollbar {
  width: 12px;
}

.sidebar-nav::-webkit-scrollbar-track {
  background: #3C4B64;
}

.sidebar-nav::-webkit-scrollbar-thumb {
  background-color: blue;
  border-radius: 20px;
  border: 3px solid #3C4B64;
}
/* Fin stilos scrollbar del sidebar */

/* Colores de fondo para sweetalert2 */
.colored-toast.swal2-icon-success {
  background-color: #a5dc86 !important;
}

.colored-toast.swal2-icon-error {
  background-color: #f27474 !important;
}

.colored-toast.swal2-icon-warning {
  background-color: #f8bb86 !important;
}

.colored-toast.swal2-icon-info {
  background-color: #3fc3ee !important;
}

.colored-toast.swal2-icon-question {
  background-color: #87adbd !important;
}

.colored-toast .swal2-title {
  color: white;
}

.colored-toast .swal2-close {
  color: white;
}

.colored-toast .swal2-html-container {
  color: white;
}
/* Fin Colores de fondo para sweetalert2 */

/* Botón arriba */
.cmn-divfloat {
  position: fixed !important;
  bottom: 45px;
  right: 15px;
  display: none;
}
/* Fin botón arriba */